import React from 'react';
import classnames from 'classnames';
import PageTitle from '../../common/PageTitle/PageTitle';
import s from './InformationPage.module.scss';

export default function InformationPage() {
  return (
    <>
      <PageTitle withBackBtn={false} title="Информация" />

      <div className={s.contentBlock}>
        <h3>
          Техническая поддержка системы управления проектной деятельностью (СУПД) и предоставление аналитической информации о реализации:
        </h3>
        <div className={s.list}>
          <span className={s.text}>Начальник отдела аналитической отчетности</span>
          <span className={s.text}>Бабенко Максим Алексеевич</span>
          <a className="external" href={'mailto:m.babenko@ficto.ru'}>
            m.babenko@ficto.ru
          </a>
          <span className={s.text}>
            <a className="external" href={'tel:+79262161962'}>
              8 (926) 216-19-62
            </a>
          </span>
        </div>
        <div className={s.list}>
          <span className={s.text}>
            Заместитель начальника управления мониторинга центра аналитики и больших данных
          </span>
          <span className={s.text}>Веденков Денис Витальевич</span>
          <a className="external" href={'mailto:d.vedenkov@ficto.ru'}>
            d.vedenkov@ficto.ru
          </a>
          <span className={s.text}>
            <a className="external" href={'tel:+79151682423'}>
              8 (915) 168-24-23
            </a>
          </span>
        </div>
      </div>

      <div className={s.contentBlock}>
        <h3>
          Организационно-методическая поддержка в части подготовки отчётности в ГИИС «Электронный бюджет»:
        </h3>
        <div className={s.list}>
          <span className={s.text}>Ведущий специалист отдела экспертно-документационного сопровождения</span>
          <span className={s.text}>Шнигер Никита Олегович</span>
          <a className="external" href={'mailto:n.shniger@ficto.ru'}>
            n.shniger@ficto.ru
          </a>
          <span className={s.text}>
            <a className="external" href={'tel:+79295497905'}>
              8 (929) 549-79-05
            </a>
          </span>
        </div>
      </div>

      <div className={s.contentBlock}>
        <h3>
          Контакты кураторов мероприятий 1, 2 и 7 дорожной карты по оснащению кабинетов по учебным предметам «Основы безопасности и защиты Родины» и «Труд (Технология)»:
        </h3>
        <table className={s.table}>
          <tr>
            <td>
              <b>
                Северо-Западный федеральный округ, Уральский федеральный округ, Северо-Кавказский федеральный округ, Южный федеральный округ, Сибирский федеральный округ, ЛНР, ДНР, Запорожская и Херсонская области
              </b>
              <div>Гаврилова Дарья Дмитриевна</div>
              <div>главный специалист</div>
              <a className="external" href="tel:+79534861533">
                8 (953) 486-15-33
              </a>
              ,
              <a className="external" href="mailto:d.gavrilova@ficto.ru">
                d.gavrilova@ficto.ru
              </a>
            </td>
            <td>
              <b>
                Центральный федеральный округ, Приволжский федеральный округ, Дальневосточный федеральный округ, г. Байконур
              </b>
              <div>Котлярова Юлия Владимировна</div>
              <div>главный специалист</div>
              <a className="external" href="tel:+79092005044">
                8 (909) 200-50-44
              </a>
              ,
              <a className="external" href="mailto:yu.kotlyarova@ficto.ru">
                yu.kotlyarova@ficto.ru
              </a>
            </td>
          </tr>
        </table>
      </div>

      <div className={s.contentBlock}>
        <h3>Кураторы региональных ведомственных проектных офисов:</h3>
        <table className={s.table}>
          <tr>
            <td>
              <b>Северо-Кавказский федеральный округ</b>
              <div>Архипов Александр Петрович</div>
              <div>зам. начальника управления регионального сопровождения</div>
              <a className="external" href="tel:+79178991676">
                8 (917) 899-16-76
              </a>
              ,
              <a className="external" href="mailto:a.arkhipov@ficto.ru">
                a.arkhipov@ficto.ru
              </a>
            </td>
            <td>
              <b>Северо-Западный федеральный округ</b>
              <div>Мослякова Марина Геннадьевна</div>
              <div>главный специалист</div>
              <a className="external" href="tel:+79951500621">
                8 (995) 150-06-21
              </a>
              ,
              <a className="external" href="mailto:m.moslyakova@ficto.ru">
                m.moslyakova@ficto.ru
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <b>Дальневосточный федеральный округ</b>
              <div>Бортников Иван Николаевич</div>
              <div>главный специалист</div>
              <a className="external" href="tel:+79196936361">
                8 (919) 693-63-61
              </a>
              ,
              <a className="external" href="mailto:i.bortnikov@ficto.ru">
                i.bortnikov@ficto.ru
              </a>
            </td>
            <td>
              <b>Центральный федеральный округ</b>
              <div>Тюшина Юлия Сергеевна</div>
              <div>главный специалист</div>
              <a className="external" href="tel:+79966165502">
                8 (996) 616-55-02
              </a>
              ,
              <a className="external" href="mailto:yu.tiushina@ficto.ru">
                yu.tiushina@ficto.ru
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <b>Приволжский федеральный округ</b>
              <div>Конашенко Александра Олеговна</div>
              <div>Главный специалист</div>
              <a className="external" href="tel:+79107682353">
                8 (910) 768-23-53
              </a>
              ,
              <a className="external" href="mailto:a.konashenko@ficto.ru">
                a.konashenko@ficto.ru
              </a>
            </td>
            <td>
              <b>Сибирский федеральный округ</b>
              <div>Бортников Иван Николаевич</div>
              <div>главный специалист</div>
              <a className="external" href="tel:+79196936361">
                8 (919) 693-63-61
              </a>
              ,
              <a className="external" href="mailto:i.bortnikov@ficto.ru">
                i.bortnikov@ficto.ru
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <b>Уральский федеральный округ</b>
              <div>Брежнев Дмитрий Сергеевич</div>
              <div>начальника отдела координации региональных систем образования</div>
              <a className="external" href="tel:+79227849299">
                8 (922) 784-92-99
              </a>
              ,
              <a className="external" href="mailto:d.brezhnev@ficto.ru">
                d.brezhnev@ficto.ru
              </a>
            </td>
            <td>
              <b>Южный федеральный округ</b>
              <div>Мослякова Марина Геннадьевна</div>
              <div>главный специалист</div>
              <a className="external" href="tel:+79951500621">
                8 (995) 150-06-21
              </a>
              ,
              <a className="external" href="mailto:m.moslyakova@ficto.ru">
                m.moslyakova@ficto.ru
              </a>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <b>ДНР, ЛНР, Запорожская и Херсонская области</b>
              <div>Брежнев Дмитрий Сергеевич</div>
              <div>начальника отдела координации региональных систем образования</div>
              <a className="external" href="tel:+79227849299">
                8 (922) 784-92-99
              </a>
              ,
              <a className="external" href="mailto:d.brezhnev@ficto.ru">
                d.brezhnev@ficto.ru
              </a>
            </td>
          </tr>
        </table>
      </div>

      <div className={s.contentBlock}>
        <h3>
          Контакты кураторов мероприятий 3, 4, 5 и 6 дорожной карты по оснащению кабинетов по учебным предметам «Основы безопасности и защиты Родины» и «Труд (Технология)»:
        </h3>
        <table className={s.table}>
          <tr>
            <td>
              <b>
                Дальневосточный федеральный округ, Приволжский федеральный округ, Северо-Западный федеральный округ, Южный федеральный округ, г. Байконур
              </b>
              <div>Милова Ирина Сергеевна</div>
              <div>зам. начальника управления мониторинга центра аналитики и больших данных</div>
              <a className="external" href="tel:+79160900267">
                8 (916) 090-02-67
              </a>
              ,
              <a className="external" href="mailto:i.milova@ficto.ru">
                i.milova@ficto.ru
              </a>
            </td>
            <td>
              <b>
                ЛНР, ДНР, Запорожская и Херсонская области, Северо-Кавказский федеральный округ, Сибирский федеральный округ, Уральский федеральный округ, Центральный федеральный округ
              </b>
              <div>Семенюк Георгий Эдуардович</div>
              <div>начальник отдела мониторинга и обработки данных</div>
              <a className="external" href="tel:+79646363355">
                8 (964) 636-33-55
              </a>
              ,
              <a className="external" href="mailto:g.semeniuk@ficto.ru">
                g.semeniuk@ficto.ru
              </a>
            </td>
          </tr>
        </table>
      </div>

      <div className={s.contentBlock}>
        <h3>Инструкции:</h3>
        <ul>
          <li>
            <a
              className="external"
              href="/Инструкция по работе в подсистеме сбора и консолидации данных.pdf"
            >
              Инструкция по работе в подсистеме сбора и консолидации данных
            </a>
          </li>
          <li>
            <a
              className="external"
              href="/Инструкция_по_фотомониторингу_для_РВПО.pdf"
            >
              Инструкция по фотомониторингу для РВПО
            </a>
          </li>
          <li>
            <a className="external" href="/Инструкция_Состав_закупки.pdf">
              Инструкция "Состав закупки"
            </a>
          </li>
          <li>
            <a
              className="external"
              href="/Инструкция_Состав_закупки_Приложение_1.xlsx"
            >
              Инструкция "Состав закупки". Приложение 1
            </a>
          </li>
          <li>
            <a
              className="external"
              href="/РВПО_Инструкция_по_работе_в_подсистеме_по_инфралистам.pdf"
            >
              РВПО Инструкция по работе в подсистеме по инфралистам
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}